import React from "react";

const skills = [
  { tech: "HTML 5", tag: "fa-html5" },
  { tech: "CSS", tag: "fa-css3" },
  { tech: "Javascript", tag: "fa-js" },
  { tech: "React", tag: "fa-react" },
  { tech: "NodeJS", tag: "fa-node-js" },
  { tech: "Python", tag: "fa-python" }
];

const TechSkills = () => (
  <div id="tech" className="container-fluid c-tech-skills">
    <h3 className="c-tech-skills--title">Tech Skills</h3>
    <div className="row">
      {skills.map((skill, index) => {
        return (
          <div className="col-sm-3 col-6 c-tech-skills--skill" key={index}>
            <div>
              <i className={"font-awesome-skill fab " + skill.tag}></i>
            </div>
            <span className="c-tech-skills--skill-description">
              {skill.tech}
            </span>
          </div>
        );
      })}
    </div>
  </div>
);

export default TechSkills;
