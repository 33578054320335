import React from "react";
import "./App.scss";
import Navbar from "./Components/Navbar";
import TechSkills from "./Components/TechSkills";
import Projects from "./Components/Projects";

function App() {
  return (
    <div className="App">
      {/* Navigation Bar */}
      <Navbar />

      {/* Jumbotron */}
      <div className="jumbotron c-jumbotron">
        <h1 className="c-jumbotron--title">I'm Juan Carlo</h1>
        <span className="c-jumbotron--subtitle">
          <i className="fas fa-terminal"></i> a programmer.
        </span>
      </div>

      <div id="about" className="container-fluid c-about">
        <div className="row">
          <div className="col-lg-4">
            <h3 className="c-about--title">Front-End Design.</h3>
            <p className="c-about--desc">
              I design and build websites and webapps using technologies like
              JavaScript and React.
            </p>
          </div>
          <div className="col-lg-4">
            <h3 className="c-about--title">Back-End Development.</h3>
            <p className="c-about--desc">
              I also build APIs, server-side applications and services using
              back-end technologies like NodeJS, ExpressJS, and Flask.
            </p>
          </div>
          <div className="col-lg-4">
            <h3 className="c-about--title">
              Machine Learning and Data Science.
            </h3>
            <p className="c-about--desc">
              I like working with data and algorithms using Machine Learning and
              Data Science tools like Python, Octave, and other data analysis
              tools.
            </p>
          </div>
        </div>
      </div>

      {/* Projects */}
      <Projects />
      {/* Tech Skills */}
      <TechSkills />

      <div id="cta" className="container-fluid c-cta">
        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <h3 className="c-cta--title">Let's talk.</h3>
            <p className="c-cta--desc">
              I am available to help you out if my skills and experience match
              your needs.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Me */}
      <div id="contact-me" className="container-fluid c-contact-me">
        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <h3 className="c-contact-me--title">Contact Me</h3>
          </div>
          <div className="col-lg-12">
            <div className="c-contact-me--icon">
              <a href="mailto:juan.soriano18@outlook.com">
                <i className="far fa-envelope"></i> Email
              </a>
            </div>
            <div className="c-contact-me--icon">
              <a
                href="https://github.com/juancarlosoriano"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i> Github
              </a>
            </div>
            <div className="c-contact-me--icon">
              <a
                href="https://www.linkedin.com/in/juan-carlo-s-309759196"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i> LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
