import React from "react";
import image__project from "../images/project-tracking-info-component.png";
import image__keep from "../images/keeper-app.png";

const Projects = () => (
  <div id="projects" className="container-fluid c-projects">
    <h3>Projects</h3>
    <div className="c-project__item">
      <p className="c-project__item-desc">Landing Page Project</p>
      <a href="https://juancarlosoriano.github.io/project-tracking-info-component/">
        <img
          className="c-project__item-img"
          src={image__project}
          alt="project-tracking-info-component-img"
        />
      </a>
    </div>
    <div className="c-project__item">
      <p className="c-project__item-desc">Google Keep Clone -- Keeper App</p>
      <a href="https://juancarlosoriano.github.io/keeper/">
        <img
          className="c-project__item-img"
          src={image__keep}
          alt="project-tracking-info-component-img"
        />
      </a>
    </div>
  </div>
);

export default Projects;
