import React from "react";

const Navbar = () => (
  <nav id="navbar" className="navbar navbar-expand-md navbar-dark">
    <a className="navbar-brand" href="#navbar">
      Juan Carlo
    </a>
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a className="nav-link" href="#navbar">
            Home
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#about">
            About
          </a>
        </li>
        {/* <li className="nav-item">
          <a className="nav-link" href="#tech">
            Tech
          </a>
        </li> */}
        <li className="nav-item">
          <a className="nav-link" href="#projects">
            Projects
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#contact-me">
            Contact Me
          </a>
        </li>
      </ul>
    </div>
  </nav>
);

export default Navbar;
